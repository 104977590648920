<template>
  <div class="bgcard">
    <div class="usermsg">
      <el-form :model="upFrom" :rules="rules" ref="ruleForm">
        <div class="picture">
          <p>{{$t('lang.headportrait')}}</p>
          <div class="format">
            <!-- <span>{{$t('lang.OnlyJPGandPNGformatsaresupported')}}</span> -->
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :headers="headers"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
							:file-list="fileList"
            >
              <div class="avatar-box">
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <div class="upload-icon" v-if="imageUrl !=='@/assets/imgs/mrtx.png'">
                  <img src="../assets/imgs/sahncghuan.png" alt="" style="width:40px;height:30px">
                  <div style="font-size:12px;color:#fff;padding-top:5px">{{$t('lang.Uploadnewimage')}}</div>
                </div>
              </div>
            </el-upload>
          </div>
        </div>
        <div class="userinput">
          <el-form-item prop="name" class="updatemsg" :label="$t('lang.Nickname')">
            <el-input v-model="upFrom.name" class="elint" :placeholder="$t('lang.Pleaseenteranickname')" ></el-input>
          </el-form-item>
          <el-form-item prop="phone" class="updatemsg" :label="$t('lang.cellphonenumber')" v-show="filg">
            <el-input
              v-model="upFrom.phone"
               class="elint"
              :placeholder="$t('lang.Pleaseinputmobilephonenumber')"
							@input='input'
            ></el-input>
          </el-form-item>
          <el-form-item prop="code" class="updatemsg codes" :label="$t('lang.VerificationCode')" v-show="mobileCode">
            <el-input v-model="upFrom.code"></el-input>
            <div class="sendmsg" @click="sendmsg">
              <p v-show="msgtime">{{$t('lang.Sendverificationcode')}}</p>
              <p v-show="!msgtime">{{ count }}S</p>
            </div>
          </el-form-item>
        </div>
      </el-form>
			<div class="btn">
			  <button @click="keepuser(upFrom)">{{$t('lang.preservation')}}</button>
			  <button @click="removeset">{{$t('lang.cancel')}}</button>
			</div>
    </div>
  </div>
</template>

<script>
import * as user from "@/api/apis";
import { upload } from '@/utils/request'
import * as RegExp from "@/ui-utils/RegExp.js";
import {isemail } from '@/utils/time'
export default {
  data() {
    var phone = (rule, value, callback) => {
      var reg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
      if (!value) {
        callback(new Error(this.$t('lang.Pleaseinputmobilephonenumber')));
      } /* else if (!reg.test(value)) {
        callback(new Error(this.$t('lang.Pleaseinputthecorrectmobilephonenumber')));
      } */ else {
        this.mobileCode = true;
        callback();
      }
    };

    return {
      imageUrl: require("@/assets/imgs/mrtx.png"),
      upFrom: {
				name:'',
				phone:'',
				code:'',
				email:''
			},
			rules: {
				name: [
					{
						required: true,
						message: this.$t('lang.Pleaseenteranickname'),
						trigger: "blur",
					},
				],
				phone: [
					{
						required: true,
						validator: phone,
						trigger: "blur",
					},
				],
				code: [
					{
						required: true,
						message: this.$t('lang.Pleaseentertheverificationcode'),
						trigger: "blur",
					},
				],
			},
      uploadUrl:upload,
      headers:{
        Authorization: JSON.parse(sessionStorage.getItem('token'))
      },
      msgtime: true,
      count: "",
      timer: null,
      imgurl: "",
      mobile: "",
      mobileCode: false,
			fileList:[],
			filg:true
    };
  },
  created() {
    this.getUserInfo()
  },
  methods: {
		//获取用户信息
    getUserInfo(){
			let userdata=JSON.parse(sessionStorage.getItem("user"))
			if(!isemail(userdata.email)){
				this.filg=false
			}
      user.userId({ email: userdata.email }).then(res => {
				//console.log(res)
				this.upFrom.name=res.data.name
				this.upFrom.phone=res.data.phone
				this.upFrom.email=res.data.email
        this.imageUrl = res.data.img ? res.data.img : require("@/assets/imgs/mrtx.png");
      });
      this.mobile = userdata.mobile;
    },
    // 点击取消
    removeset(){
      this.$router.go(-1)
    },
		//修改手机号码
		input(e){
			this.mobileCode=true
			if(e==''){
				this.mobileCode=false
			}
		},
		//上传成功
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.imgurl = file.response.data;
    },
		//上传之前
    beforeAvatarUpload(file) {
      // let isIMG=file.name.split('.')[file.name.split('.').length - 1] === 'jpg' || file.name.split('.')[file.name.split('.').length - 1] === 'jpeg' || file.name.split('.')[file.name.split('.').length - 1] === 'png'
			
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isIMG) {
      //   this.$message.error(this.$t('lang.Uploadavatarimagecanonlybe')+'JPG、PNG、JPEG'+this.$t('lang.format'));
      // }
      // if (!isLt2M) {
      //   this.$message.error(this.$t('lang.Thenumberofpicturesuploadedcannotexceed')+"2MB!");
      // }
      // return isIMG && isLt2M;
    },
		//提交
    keepuser(item) {
			let userdata=JSON.parse(sessionStorage.getItem("user"))
			if(!item.name){
				this.$message.error(this.$t("lang.Pleaseenteranickname"));
				return false;
			}
			if(userdata.phone&&!item.phone){
				this.$message.error(this.$t("lang.Pleaseinputmobilephonenumber"));
				return false;
			}
			if(item.phone&&userdata.phone!=item.phone&&!item.code){
				this.$message.error(this.$t("lang.Pleaseentertheverificationcode"));
				return false;
			}
      let modifymsg = {
        code: item.code,
        email: item.email,
        name: item.name,
				phone:'',
				img:''
      };
			if(item.phone&&userdata.phone!=item.phone){
				modifymsg.phone= item.phone
			}
			if(this.imgurl.path){
				modifymsg.img=this.imgurl.path
			}
      user.userUpdate(modifymsg).then((res) => {
				this.$message.success(res.msg)
				this.$router.go(-1)
      });
    },
		//发送验证码
    sendmsg() {
			/* if(!RegExp.mobile.test(this.upFrom.phone)){
				this.$message.error(this.$t('lang.Pleaseenterthecorrectcontactphone'))
				return false
			} */
      if (this.upFrom.phone && !this.timer) {
        let sendval = {
          phone: this.upFrom.phone,
					code:3
        };
        user.sendPhoneCode(sendval).then((res) => {
          let sk=res.msg
          if(res.msg){
          	let wq=sk.split('-')
          	if(sessionStorage.getItem('language')&&sessionStorage.getItem('language')=='zhong'){
          		sk=wq[0]
          	}else{
          		sk=wq[1]
          	}
          	this.$message.success(sk);
          	if(wq[0].indexOf('操作过于频繁')!=0){
          		this.getCode();
          	}
          }
        });
      }
    },
		//倒计时
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.msgtime = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.msgtime = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.userinput .el-form-item__content{
  width: 70% !important;
}
/deep/.codes{
	.el-input__inner{
		padding: 0 1.25rem 0 .15rem;
	}
}
.bgcard {
  width: 100%;
  height: 100%;
  background: #e5e5e5;
  display: flex;
  justify-items: center;
  flex-direction: column;
  align-items: center;
}
.usermsg {
  width: 5.8rem;

  p {
    // margin-right: 25px !important;
    font-size: .18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #666666;
  }
  .picture {
    display: flex;
    margin-top: .79rem;
    .format {
      width: 3.7rem;
      display: flex;
      flex-direction: column;
      margin-bottom: .45rem;
      span {
        font-size: .14rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
        margin-bottom: .23rem;
      }
    }
    img {
      width: 1.38rem;
      height: 1.38rem;
      background: #0a0f30;
      opacity: 0.62;
      border-radius: 50%;
    }
    p {
      padding-left: 4px;
      margin-right: .56rem;
    }
  }
  .btn {
    display: flex;
    align-content: center;
    justify-content: center;
    padding-right: .6rem;
    margin-top: .5rem;
    button {
      width: 1.12rem;
      height: .5rem;
      outline-style: none;
      border: none;
      background: #0a0f30;
      border-radius: .25rem;
      font-size: .18rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      &:first-of-type {
        margin-right: .32rem;
      }
    }
  }
}
/* 验证码发送 */
.sendmsg {
  width: 1.06rem;
  height: .44rem;
  background: #0a0f30;
  border-radius: 6px;
  position: absolute;
  cursor: pointer;
  right: .5rem;
  top: 50%;
	transform: translateY(-50%);
  z-index: 10;
  p {
    font-size: .16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: .44rem;
    text-align: center;
  }
}
.avatar-box{
  // background-color: #333;
  position: relative;
}
.upload-icon{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} 
</style>
